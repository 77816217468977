/* eslint-disable react/jsx-no-useless-fragment */

import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

import { Text } from '@/components/atoms';
import style from './landingCountdown.module.scss';

const Number = ({ number, name }) => {
  return (
    <div className={style.time}>
      <Text
        className={style.number}
        textColor="white"
      >
        {number}
      </Text>
      <Text
        className={style.lable}
        textColor="white"
      >
        {name}
      </Text>
    </div>
  );
};

const LandingCountdown = () => {
  const router = useRouter();
  const [countdown, setCountdown] = useState({
    load: false,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const settime = () => {
    const dDay = new Date('2024-11-04T00:00:00');
    const now = new Date();

    const diference = dDay.getTime() - now.getTime();
    const segundosTotales = Math.floor(diference / 1000);
    const minutosTotales = Math.floor(segundosTotales / 60);
    const horasTotales = Math.floor(minutosTotales / 60);
    const dias = Math.floor(horasTotales / 24);

    const horas = horasTotales % 24;
    const minutos = minutosTotales % 60;
    const segundos = segundosTotales % 60;
    if (horas < 1 && minutos < 1 && segundos < 1) {
      router.push('/cyber-monday/p/1');
    }
    setCountdown({
      load: true,
      days: dias,
      hours: horas,
      minutes: minutos,
      seconds: segundos
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      settime();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return countdown.load ? (
    <div style={{ display: 'flex', gap: '30px' }}>
      <Number
        number={countdown.days}
        name="DÍAS"
      />
      <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        <Number
          number={countdown.hours}
          name="HORAS"
        />
        <Text
          textColor="white"
          className={style.dots}
        >
          :
        </Text>
        <Number
          number={countdown.minutes}
          name="MINUTOS"
        />
        <Text
          textColor="white"
          className={style.dots}
        >
          :
        </Text>
        <Number
          number={countdown.seconds}
          name="SEGUNDOS"
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

Number.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired
};

export default LandingCountdown;
